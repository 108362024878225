import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pages: {},
        config:{}
    },
    mutations: {
        setConfig(state, payload) {
            state.config = payload.reduce((acc, item) => {
                acc[item.type] = item.value;
                return acc;
            }, {});
        }
    },
    actions: {
    },
    modules: {
    }
})
