/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "normalize.css";
import "@/assets/css/common.scss"
import moment from 'moment'
// import rem from '@/assets/js/rem'
import { JSEncrypt } from 'jsencrypt'
import ElementUI from 'element-ui'
// import VCharts from 'v-charts'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/css/common.scss'
import dayjs from 'dayjs'
import "amfe-flexible/index.js";
import axios from "axios";
Vue.prototype.axios = axios;
axios.defaults.timeout = 600 * 1000;
axios.defaults.withCredentials = true;
Vue.prototype.dayjs = dayjs
Vue.prototype.$encryptedData = function (password) {
  // new一个对象
  let encrypt = new JSEncrypt()
  // 设置公钥
  encrypt.setPublicKey('MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJzsJM71CVVk85yWGhRTvBmPYPhdXVzvVW8jVsoE430QtJNUGvNt9s7ZSr0GnOFvF++zZrnK5Zi064X/a6qNwPsCAwEAAQ==')
  // password是要加密的数据,此处不用注意+号,因为rsa自己本身已经base64转码了,不存在+,全部是二进制数据
  let result = encrypt.encrypt(password)
  return result
}

// Vue.use(rem)
Vue.prototype.$moment = moment
// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(ElementUI, {
  size: 'small'
})
// Vue.use(VCharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
