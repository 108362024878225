import axios from 'axios'
import { Message } from 'element-ui'
// const qs = require('qs')

axios.interceptors.request.use(
    config => {
        // 登录接口和刷新token接口绕过
        if (config.url.indexOf('/login') >= 0) {
            return config;
        }
        let ecolUserInfo = JSON.parse(localStorage.getItem('ecolUserInfo'))
        let accessToken
        let refreshToken
        if (ecolUserInfo) {
            accessToken = ecolUserInfo.accessToken || ''
            refreshToken = ecolUserInfo.refreshToken || ''
        }
        // 添加请求头
        config.headers.common["authorization"] = accessToken ? accessToken : null;
        config.headers.common["refresh_token"] = refreshToken ? refreshToken : null;

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
const service = {
    successHandler (res) {
        if (res.status === 200) {
            const isSuccess = res.data.code === 200 && res.data;
            const hasValidData = res.data.result_data|| res.data.result || res.data.result_img || res.data.outputs || res.data.class || res.data.audio_result;
            if (isSuccess || hasValidData) {
                return res.data;
            } else {
                Message.error(res.data.msg || '接口调用异常');
            }
        } else {
           
            Message.error('网络请求失败');
        }
        
    },
    errHandler (err, { errHandler = true }) {
        // if (errHandler && err.resultCode && err.resultCode !== 401) {

        // }
        Message.error(err.resultMsg || err.resultMsg || '接口调用异常')

        return Promise.reject(err)
    },
    get (url, options, otherConfig = {}) {
        return axios
            .get(url, {
                params: options,
                ...otherConfig
            })
            .then(this.successHandler)
            .catch(err => this.errHandler(err, otherConfig))
    },
    post (url, options, otherConfig = {}) {
        return axios
            .post(url, options, otherConfig)
            .then(this.successHandler)
            .catch(err => this.errHandler(err, otherConfig))
    },
    put (url, options, otherConfig = {}) {
        return axios
            .put(url, options, otherConfig)
            .then(this.successHandler)
            .catch(err => this.errHandler(err, otherConfig))
    },
    delete (url, options, otherConfig = {}) {
        return axios
            .delete(url, {
                data: options,
                ...otherConfig
            })
            .then(this.successHandler)
            .catch(err => this.errHandler(err, otherConfig))
    }
}

export default service
