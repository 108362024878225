import service from './services'
/* eslint-disable */
const api = {
    login (params) {
        return service.post('/user/login', params);
    },
    register (params) {
        return service.post('/ecol/user/user', params);
    },

    // 获取字典
    queryDictDetail (code) {
        return service.get(`/ecol/ecolDict/list/${code}`)
    },
    // app列表
    queryApp (params) {
        return service.post('/ecol/app/apps', params)
    },
    //首发app列表
    queryInititalApp (params) {
        return service.get('/ecol/ordinary/apps', params)
    },

    queryGateWayApp (params) {
        return service.post('/ecol/app/apps/ordinary', params);
    },

    queryGateWayAppDetail (id) {
        return service.get(`/ecol/app/detail/ordinary/${id}`);
    },
    queryAppDetailById (id) {
        return service.get(`/ecol/app/detail/${id}`);
    },
    // 企业列表
    queryCompany (params) {
        return service.post('/ecol/ecolCompany/ecolCompanys', params)
    },
    // 发布app
    publishApp (params) {
        return service.post('/ecol/app/app', params)
    },
    // 文件上传
    fileUpload (params, onUploadProgress) {
        return service.post('/ecol/app/upload/dir', params, {
            onUploadProgress,
        });
    },
    // 新增企业
    createCompany (params) {
        return service.post('/ecol/ecolCompany/ecolCompany', params)
    },
    // 修改app
    modifyApp (params) {
        return service.put('/ecol/app/app', params)
    },


    // 企业状态
    queryCompanyStatus () {
        return service.get('/ecol/ecolCompany/userCompCheck')
    },
    // 企业列表
    queryCompany (params) {
        return service.post('/ecol/ecolCompany/ecolCompanys', params)
    },
    // 企业详情
    queryCompanyDetail (id) {
        return service.get(`/ecol/ecolCompany/${id}`)
    },
    // 企业审核
    companyExmian (params) {
        return service.post('/ecol/ecolCompany/checkCompany', params)
    },
    // 企业修改
    companyModify (params) {
        return service.put('/ecol/ecolCompany/ecolCompany', params)
    },

    // 应用上下线
    appGoOffLine (id, upDown) {
        return service.get(`/ecol/app/status/${id}/${upDown}`)
    },
    // 应用删除
    deleteApp (id) {
        return service.delete(`/ecol/app/${id}`)
    },


    // 应用top10
    homeAppTopList () {
        return service.get(`/ecol/home/homeAppList`)
    },
    // 获取pv列表
    getPvList (params) {
        return service.get(`/ecol/home/homeAppPvList`, params)
    },
    // 获取访问量列表
    getSummaryList (params) {
        return service.get(`/ecol/home/homeAppSummary`, params)
    },
    // 获取app统计列表
    getAppSummaryList (params) {
        return service.get(`/ecol/home/homeSummary`, params)
    },
    //获取系统配置参数
    getAppConfig () {
        return service.get(`/config/ecol/sys/app/systemConfig`)
    },


    /**
     * 图像识别
     */
    //识别记录

    testRecord (url) {
        return service.post(`/apitest${url}`, {}, { 'Content-Type': 'application/json' })
    },
    modelApiTest (url, data) {
        return service.post(`/apitest${url}`, data)
    }

}
export const FileUpload = (params) => {
    return service.post('/ecol/app/upload/dir', params);
}
export default api
