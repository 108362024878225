/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    // 登陆注册
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/login.vue')
    },
    // 外部登录中转
    {
        path: '/loginCheck',
        name: 'loginCheck',
        component: () =>
            import('@/views/loginCheck.vue')
    },
    // 主页
    {
        path: '/',
        name: 'home',
        component: () =>
            import('@/views/Home.vue')
    },
    // app
    {
        path: '/appMarket',
        name: 'appMarket',
        component: () =>
            import('@/views/appMarket/Index.vue')
    },
    {
        path: '/appDetail',
        name: 'appDetail',
        component: () =>
            import('@/views/appMarket/Detail.vue')
    },
    {
        path: '/appPublish',
        name: 'appPublish',
        component: () =>
            import('@/views/appMarket/Publish.vue')
    },
    {
        path: '/appPublicize',
        name: 'appPublicize',
        component: () =>
            import('@/views/appMarket/Publicize.vue')
    },
    {
        path: '/appExperience/:id/:companyName/:appType',
        name: 'appExperience',
        component: () =>
            import('@/views/appMarket/Experience.vue')
    },
    // 企业
    {
        path: '/company',
        name: '',
        component: () => import('@/views/company/Index.vue'),
        children: [
            {
                path: 'dashboard',
                name: '首页',
                component: () => import('@/views/company/dashboard/Index.vue')
            },
            {
                path: 'info',
                name: 'companyInfo',
                component: () => import('@/views/companyMenu/Info.vue')
            },
            {
                path: 'apps',
                name: '应用管理',
                component: () => import('@/views/companyApps/Index.vue'),
                children: [
                    {
                      path: 'publish',
                      name: '应用发布',
                      component: () => import('@/views/companyApps/Publish.vue')
                    }
                  ]
            },
            {
                path: 'manage',
                name: '企业管理',
                component: () => import('@/views/companyManage/Index.vue'),
                children: [
                    {
                        path: 'authentication',
                        name: 'companyManageEditor',
                        component: () => import('@/views/companyManage/Editor.vue')
                    },
                    {
                        path: 'auth',
                        name: '企业认证',
                        component: () => import('@/views/companyManage/Auth.vue')
                    }
                   
                ]
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    if (!to.path.startsWith('/company')) {
        next();
        return;
    }
    const userInfoString = window.localStorage.getItem('ecolUserInfo');
    if (!userInfoString) {
        next('/login');
        return;
    }
    const companyInfoString = window.localStorage.getItem('company');
    let companyInfo = null;
    try {
        companyInfo = JSON.parse(companyInfoString);
        if (!companyInfo || companyInfo.status !== 1) {
            if (to.path !== '/company/manage/auth') {
                next('/company/manage/auth');
                return;
            }
        }
    } catch (e) {
        console.error("Error parsing companyInfo:", e);
    }
    if (to.path === '/company' && userInfoString && companyInfo && companyInfo.status === 1) {
        next('/company/dashboard');
    } else {
        next();
    }
});



export default router
